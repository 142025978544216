<template>
  <CRow class>
    <CCol md="6" xs="12">
      <CCard>
        <CCardBody>
          <AddReceivingAddressComponent />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AddReceivingAddressComponent from '@/components/ReceivingAddress/AddReceivingAddressComponent';
import endpoints from '@/constants/endpoints';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'AddReceivingAddress',
  components: { AddReceivingAddressComponent },
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
};
</script>

<style>
</style>
