<template>
  <div class="form-create-receiving" @change="isFormChange=true">
    <div class="back-to-receiving">
      <router-link to="/admin/membership-settings/receiving-address">
        <CIcon name="cil-arrow-left" class="mr-1" />
        {{$t('ADD_RECEIVING_ADDRESS_COMPONENT_BUTTON_BACK_TO_RECIVING_ADDRESS')}}
      </router-link>
    </div>
    <ValidationObserver ref="observer" @submit.prevent="actionHandler">
      <ValidationProvider
        :name="$t('ADD_RECEIVING_ADDRESS_COMPONENT_DROPDOWN_PLATFORM_LABEL')"
        rules="required"
        v-slot="{ errors }"
      >
        <CSelect
          v-if="!isEdit"
          :label="$t('ADD_RECEIVING_ADDRESS_COMPONENT_DROPDOWN_PLATFORM_LABEL')"
          horizontal
          :value.sync="dataAddress.platform"
          :options="platformOptions"
        />
        <div v-if="isEdit" class="form-edit">
          <label>{{$t('ADD_RECEIVING_ADDRESS_COMPONENT_DROPDOWN_PLATFORM_LABEL')}}</label>
          <p>{{addressDetails.currency_symbol}}</p>
        </div>
        <CRow class="group-msg-err">
          <CCol :sm="{offset:'3'}"></CCol>
          <CCol>
            <span class="error-msg mt-1">{{errors[0]}}</span>
          </CCol>
        </CRow>
      </ValidationProvider>
      <ValidationProvider
        :name="$t('ADD_RECEIVING_ADDRESS_COMPONENT_INPUT_ADDRESS_LABEL')"
        rules="required"
        v-slot="{ errors }"
      >
        <CInput
          v-if="!isEdit"
          :label="$t('ADD_RECEIVING_ADDRESS_COMPONENT_INPUT_ADDRESS_LABEL')"
          horizontal
          v-model="dataAddress.address"
        />
        <div v-if="isEdit" class="form-edit">
          <label>
            {{$t('ADD_RECEIVING_ADDRESS_COMPONENT_INPUT_ADDRESS_LABEL')}}
          </label>
          <p>{{addressDetails.wallet_address}}</p>
        </div>
        <CRow class="group-msg-err">
          <CCol :sm="{offset:'3'}"></CCol>
          <CCol>
            <span class="error-msg mt-1">{{errors[0]}}</span>
          </CCol>
        </CRow>
      </ValidationProvider>
      <ValidationProvider
        :name="$t('ADD_RECEIVING_ADDRESS_COMPONENT_INPUT_MEMO_LABEL')"
        rules
        v-slot="{ errors }"
      >
        <CTextarea
          :label="$t('ADD_RECEIVING_ADDRESS_COMPONENT_INPUT_MEMO_LABEL')"
          horizontal
          v-model="dataAddress.description"
        />
        <CRow class="group-msg-err">
          <CCol :sm="{offset:'3'}"></CCol>
          <CCol>
            <span class="error-msg mt-1">{{errors[0]}}</span>
          </CCol>
        </CRow>
      </ValidationProvider>

      <CRow class="form-actions mt-3">
        <CCol lg="12" md="12">
          <CButton
            @click="openWarningLeaveModal"
            class="btn-cancel"
          >{{$t('ADD_RECEIVING_ADDRESS_COMPONENT_BUTTON_CANCEL')}}</CButton>
          <CButton
            color="primary"
            class="btn btn-save"
            @click="handleSubmit(dataAddress)"
          >{{$t('ADD_RECEIVING_ADDRESS_COMPONENT_BUTTON_SAVE')}}</CButton>
        </CCol>
      </CRow>
    </ValidationObserver>

    <StakingModal :linkTo="linkTo" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { getCryptoPlatformTypes } from '@/enums';
import StakingModal from '@/components/StakingModal/index';
import stringHelper from '@/utils/string-helper';

export default {
  name: 'AddReceivingAddressComponent',
  props: {},
  components: { StakingModal },
  data() {
    return {
      dataAddress: {
        platform: 'BTC',
        address: '',
        description: '',
      },
      platformOptions: getCryptoPlatformTypes(),
      isFormChange: false,
      warningLeavePage: false,
      isEdit: false,
      addressDetails: [],
      receivingAddressId: '',
      linkTo: '/admin/membership-settings/receiving-address',
    };
  },
  async mounted() {
    const receivingAddressId = this.$route.params.receivingAddressId;
    if (receivingAddressId) {
      this.isEdit = true;
      this.receivingAddressId = receivingAddressId;
      await this.getReceivingAddressDetails(receivingAddressId);
      this.dataAddress.description = this.addressDetails.description;
    }
  },
  methods: {
    async save(data) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      const params = {
        platform: data.platform,
        address: data.address,
        description: data.description,
      };
      try {
        const res = await this.$http.post(endpoints.createReceivingAddress, params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('ADD_RECEIVING_ADDRESS_COMPONENT_CREATE_ADDRESS_SUCCESS_MESSAGE'),
        });
        return this.$router.back();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    openWarningLeaveModal() {
      if (this.isFormChange) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.linkTo);
      }
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    async getReceivingAddressDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getReceivingAddressDetails(id));
        this.addressDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async handleSubmit(data) {
      if (this.isEdit) {
        await this.updateMemo(data);
      } else {
        await this.save(data);
      }
    },
    async updateMemo(data) {
      try {
        const params = {
          description: data.description,
        };
        await this.$http.put(endpoints.updateReceivingAddressDetails(this.receivingAddressId), params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('ADD_RECEIVING_ADDRESS_COMPONENT_UPDATE_MEMO_SUCCESS_MESSAGE'),
        });
        this.$router.back();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('RECEIVING_ADDRESS_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@mixin labels {
  font-size: 14px;
  font-weight: 300;
  color: #3e4b65;
  text-align: right;
  padding: 0;
  line-height: 32px;
}
@mixin contents {
  color: #3a4a7f;
  font-size: 14px;
  font-weight: 500;
}
.form-create-receiving {
  padding: 0 25px 0 5px;
  .back-to-receiving {
    margin: 10px 0 30px;
    a {
      color: #695fe5;
      text-decoration: none;
    }
  }
  .form-group {
    margin-bottom: 0;
    label {
      @include labels;
    }
    textarea,
    input {
      @include contents;
    }
  }
  .form-edit {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin: 0 -5px;
    label {
      width: 25%;
      @include labels;
      margin: 0;
    }
    p {
      width: 75%;
      @include contents;
      line-height: 32px;
      margin: 0;
      padding: 0 5px;
    }
    + .group-msg-err {
      margin-bottom: 10px;
    }
  }
  .group-msg-err {
    margin-bottom: 21px;
    .error-msg {
      color: #ec0a0a;
    }
  }
  .form-actions {
    text-align: right;
    .btn {
      border: none;
      margin: 0 0 15px 15px;
      &.btn-cancel {
        color: #6c65e4;
        margin: 0 10px 15px 15px;
      }
      &.btn-save {
        border-radius: 4px;
        width: 146px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        background: #3b4b7e;
      }
    }
  }
}
</style>
